.testTitle {
  color: #151515;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
}
.content {
  height: 90vh;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  min-width: 440px;
  max-width: 440px;
  /*max-width: 540px;*/
}
.textSmall {
  color: #151515;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  opacity: 0.64;
}

@media (max-width: 1375px) {
  .content {
    min-width: unset;
  }
}

@media screen and (max-width: 1310px) {
  .content {
    /*padding-top: 40px;*/
    height: 390px;
    padding-left: 0;
  }
  .img {
    max-height: 200px;
  }
}

.container h2 {
  color: #6100ff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.785px;
  margin-bottom: 24px;
}

.container h3 {
  cursor: default;
  color: #151515;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  letter-spacing: -0.785px;
  margin-bottom: 8px;
}

.foodCards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}

@media (max-width: 550px) {
  .foodCards {
    grid-template-columns: 1fr;
  }
}

.foodCard {
  border-radius: 16px;
  background: #f2f2f2;
  height: 200px;
  display: grid;
  grid-template-rows: 2fr 1fr;
}

.image {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
}

.foodCardDescription {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.divider {
  stroke-width: 1px;
  stroke: rgba(0, 0, 0, 0.08);
  margin: 0;
}

.container p {
  cursor: default;
  color: #151515;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.785px;
}

.recipeContainer {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  padding: 30px 35px;
  background: white;
  border-radius: 24px;
  box-shadow: 0px 4px 200px 0px rgba(97, 0, 255, 0.24);
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: calc(90dvh - 10%);
  width: 60%;
}

.recipeHeader {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: center;
}

.recipeHeader h2,
.recipeHeader span {
  color: #151515;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.785px;
  margin: 0;
}

.recipeHeader h2 {
  font-size: 24px;
}

.recipeHeader span {
  cursor: pointer;
  font-size: 32px;
}

.scrollableLayout {
  overflow: auto;
  position: relative;
}

.preparationCard {
  background: url("./assets/pattern.png") no-repeat;
  background-size: cover;
  border-radius: 16px;
  padding: 16px 32px 16px 16px;
  box-shadow: 0px 16px 32px -9px #6100ff63;
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
  height: 100%;
  max-height: 80px;
}

.preparationCard img {
  position: absolute;
  top: -2px;
  right: 0px;
}

.preparationCard h3 {
  width: max-content;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.785px;
  margin: 0;
}

.preparationCard p {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.785px;
}

.preparationContainer {
  display: flex;
  gap: 24px;
  padding-left: 22px;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  max-width: 100%;
  min-height: 80px;
  overflow: visible;
}

.recipeText {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 500px;
  padding-right: 20px;
  word-wrap: break-word;
}

.preparation,
.ingredients {
  padding: 16px;
  border-radius: 15px;
  border: solid 1.5px var(--grayscale-color-200);
}

.recipeText h1,
.recipeText h2,
.recipeText h3,
.recipeText h4,
.recipeText h5,
.recipeText h6 {
  font-family: Inter;
  font-weight: 500;
  color: var(--grayscale-color-900) !important;
  margin-bottom: 24px;
}

.recipeText h1 {
  font-size: 32px;
}

.recipeText h2 {
  font-size: 28px;
}

.recipeText span {
  background-color: transparent !important;
}

.recipeText h3 {
  font-size: 24px;
}

.recipeText h4 {
  font-size: 20px;
}

.recipeText h5 {
  font-size: 18px;
}

.recipeText h6 {
  font-size: 16px;
}

.recipeText u {
  color: var(--primary-color-900);
}

.recipeText p,
.recipeText span,
.recipeText ul,
.recipeText li {
  color: var(--grayscale-color-500) !important;
  font-size: 16px;
}

.recipeText li {
  margin-bottom: 21px;
}

.sectionHeader {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;
  color: #151515;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.785px;
}

@media (max-width: 988px) {
  .recipeContainer {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    transform: none;
    width: 100%;
    max-height: 80vh;
    padding: 30px 16px;
  }
  .preparationContainer {
    gap: 4px;
  }
}
.innerHTML {
  padding: 0 15px;
}

.wrapContainer {
  position: relative;
  max-width: 540px;
  width: 100%;
  z-index: 2;
  overflow-x: auto;
  overflow-y: auto;
}

.container {
  display: flex;
  position: absolute;
  top: 0;
  width: 500px;
  flex-direction: column;
  gap: 56px;
  padding-bottom: 30px;
}

.container .wrapBlock {
  cursor: default;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.wrapBlock.activate {
  cursor: pointer;
}
.wrapBlock .content {
  display: flex;
  align-items: center;
  gap: 24px;
}
.container .activate.wrapBlock:not(:first-child)::before {
  background: #6100ff;
}
.container .wrapBlock:not(:first-child)::before {
  content: "";
  position: absolute;
  top: -113px;
  left: 31px;
  width: 3px;
  background: linear-gradient(0deg, #c4bcdb 0%, #c4bcdb 100%), #c4bcdb;
  height: 197px;
}

.img {
  min-width: 64px;
  z-index: 100;
  position: relative;
}

.textBlock {
  opacity: 0.3;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  gap: 8px;
  font-weight: 400;
}
.wrapBlock.activate .textBlock {
  opacity: inherit;
}

.step {
  color: rgba(21, 21, 21, 0.24);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.testTitle {
  color: #151515;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.bodyText {
  color: rgba(21, 21, 21, 0.8);
  font-size: 16px;
  max-width: 352px;
  font-style: normal;
  font-weight: 400;
  font-feature-settings:
    "clig" off,
    "liga" off;
  line-height: 180%; /* 28.8px */
  letter-spacing: -0.785px;
}

.img {
  z-index: 100;
}
@media screen and (max-width: 1310px) {
  .wrapContainer {
    padding-top: 24px;
    min-width: inherit;
    max-width: inherit;
  }
  .step {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
  .testTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }
  .bodyText {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
  }
  .container {
    position: inherit;
    width: 100%;
    top: inherit !important;
  }
  .wrapContainer {
    /*order: 2;*/
    width: 100%;
    max-width: inherit;
    overflow: inherit;
    max-height: inherit;
  }
  .wrapContainer::before {
    display: none;
  }
  .wrapContainer.fogTop::after {
    display: none;
  }
}
@media screen and (max-width: 419px) {
  .container .wrapBlock:not(:first-child)::before {
    top: -131px;
    height: 229px;

    /*background: black;*/
  }
}

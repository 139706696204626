.container {
  font-size: 1em;
  display: flex;
  gap: 1.5em;
  align-items: center;
}

.container > div {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.checklist {
  height: clamp(80px, 8.75em, 140px);
  width: clamp(80px, 8.75em, 140px);
}

.container > div h1 {
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: clamp(16px, 1.25em, 20px);
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
  letter-spacing: -0.785px;
}

.container > div p {
  color: rgba(21, 21, 21, 0.64);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: clamp(12px, 0.875em, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.785px;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
}

.listWrapper {
  padding-right: 10px;
  width: 410px;
  position: relative;
  height: 100%;
}

.list {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-gutter: stable;
}

.questionnaire {
  margin: auto;
  width: 100%;
  height: 100%;
}

@media (max-width: 988px) {
  .container {
    overflow: visible;
  }
  .listWrapper {
    width: 100%;
    height: initial;
    overflow: initial;
  }
  /*.questionnaire {*/
  /*    max-height: 90vh;*/
  /*}*/
}

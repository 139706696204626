.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.containerChildren {
  padding: 0 0 0 40px;
  display: flex;
  flex-direction: column;
}

.parent {
  cursor: pointer;
  display: grid;
  white-space: nowrap;
  grid-template-columns: 24px min-content min-content;
  align-items: center;
  gap: 14px;
  width: calc(100% + 32px);
  border: none;
  background: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  text-decoration: none;
  color: white;
  border-radius: 0 16px 16px 0;
  margin-left: -32px;
  padding: 18px 0 18px 32px;
}

.parentActive {
  color: var(--primary-color-900);
  background-color: white;
  position: relative;
  z-index: 1;
}

.parentActive::before {
  left: 0;
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -50px;
  height: 50px;
  width: 25px;
  border-top-left-radius: 25px;
  box-shadow: 0 -25px 0 0 white;
}

.parentActive::after {
  left: 0;
  content: "";
  position: absolute;
  background-color: transparent;
  top: -50px;
  height: 50px;
  width: 25px;
  border-bottom-left-radius: 25px;
  box-shadow: 0 25px white;
}

.children {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  padding: 12px 0;
  border-radius: 0 16px 16px 0;
  margin-left: -72px;
  padding-left: 72px;
}

.childrenActive {
  color: var(--primary-color-900);
  background-color: white;
  position: relative;
}

.childrenActive::before {
  content: "";
  position: absolute;
  background-color: transparent;
  left: 0;
  bottom: -50px;
  height: 50px;
  width: 25px;
  border-top-left-radius: 25px;
  box-shadow: 0 -25px 0 0 white;
}

.childrenActive::after {
  content: "";
  position: absolute;
  background-color: transparent;
  left: 0;
  top: -50px;
  height: 50px;
  width: 25px;
  border-bottom-left-radius: 25px;
  box-shadow: 0 25px white;
}

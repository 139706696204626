.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 26px 0 -15px 0;
}

.container > p {
  color: white;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Inter, sans-serif;
  font-size: 14px;
  padding-left: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}

.picker {
  border-radius: 15px;
  text-align: left;
  padding: 8px 15px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.activeStatus {
  color: var(--primary-color-900);
  background: white;
}

.notActiveStatus {
  color: white;
  background: #4900bf;
}

.container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 20px 0;
  height: 100%;
}

.shadow {
  height: 100%;
  width: 100%;
  background: url("../../images/purpleBackground.svg");
  background-size: cover;
  overflow-x: visible;
  right: -20%;
  position: fixed;
  pointer-events: none;
}

.isLoaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1310px) {
  .container {
    flex-direction: column-reverse;
    align-items: center;
    max-width: inherit;
    width: 100%;
  }
  .shadow {
    top: -300px;
    right: -16px;
    position: absolute;
  }
  .isLoaderContainer {
    margin-top: 200px;
  }
}

@media (max-width: 988px) {
  .shadow {
    right: -12px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 44px;
  margin-left: 24px;
  max-height: 200px;
  margin-bottom: 10px;
  background: white;
}

.patient {
  display: flex;
  gap: 16px;
  align-items: center;
}

.name {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--grayscale-color-900);
}

.test {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: var(--grayscale-color-900);
}

.patient img {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

.buttonContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.buttonContainer button {
  background: var(--primary-color-900);
  border: none;
  padding: 4px 40px;
  border-radius: 12px;
  color: white;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  cursor: pointer;
}

.container {
    width: 100%;
    height: 100%;
    display: grid;
    gap: 9px;
    grid-template-columns: 434px 1fr;
}

.dropdownListContainer {
    position: relative;
}

.dropdownListWrapper {
    padding: 0 8px 0 4px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
}

.container {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: white;
  position: absolute;
  padding: 36px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  box-shadow: 0px 4.41472px 34px rgba(97, 0, 255, 0.08);
}

.buttons {
  display: flex;
  gap: 10px;
}

.cancelButton,
.submitButton {
  font-size: 16px;
  padding: 8px 30px;
}

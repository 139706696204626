.container {
  border-radius: 8px;
  border: none;
  min-width: 38px;
  height: 41px;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 180%; /* 25.2px */
  letter-spacing: -0.785px;
  background: var(--primary-color-150);
}

.container:hover {
  cursor: pointer;
}

.selected {
  padding: 4px 12px 12px 12px;
  background: var(--primary-color-900);
  color: var(--white);
}

.initial {
  padding: 4px 12px 12px 12px;
  color: var(--primary-color-900);
  background: var(--primary-color-150);
}

.completed {
  color: var(--primary-color-900);
  background: var(--primary-color-150);
  border-top: 2px solid var(--primary-color-900);
  border-right: 2px solid var(--primary-color-900);
  border-bottom: 12px solid var(--primary-color-900);
  border-left: 2px solid var(--primary-color-900);
}

@media (max-width: 988px) {
  .container {
  }
}

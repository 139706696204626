.container {
  padding: 160px;
  display: flex;
  flex-direction: column;
}

.goBack {
  display: flex;
  gap: 16px;
  cursor: pointer;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.39230769872665405px;
  color: var(--primary-color-900);
  text-decoration: none;
}

.header {
  margin: 32px 0 24px 0;
  font-family: Inter;
  font-size: 28px;
  font-weight: 500;
  line-height: 31px;
  color: var(--grayscale-color-900);
}

.primaryText {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: var(--grayscale-color-800);
}

.warningCard {
  display: flex;
  align-items: start;
  gap: 10px;
  margin-top: 24px;
  padding: 17px;
  min-height: 95px;
  min-width: fit-content;
  background: rgba(62, 91, 239, 0.08);
  border-radius: 8px;
}

.warningText,
.linkText {
  color: var(--primary-color-900);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.linkText {
  text-decoration: underline solid var(--primary-color-900) 2px;
  text-underline-offset: 3px;
  cursor: pointer;
}

@media (max-width: 1650px) {
  .container {
    padding: 80px;
  }
}

@media (max-width: 1200px) {
  .container {
    padding: 70px 60px 70px 60px;
  }
}

@media (max-width: 988px) {
  .container {
    padding: 0;
  }
}

.container {
  padding: 160px;
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
}

.header {
  font-family: Inter;
  font-size: 28px;
  font-weight: 700;
  line-height: 31px;
  margin-bottom: 24px;
  color: var(--grayscale-color-900);
}

.secondaryHeader {
  margin-bottom: 12px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: var(--grayscale-color-800);
}

.secondaryText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 340px;
  margin-bottom: 40px;
  color: var(--grayscale-color-600);
}

.input {
  padding: 12px 20px;
  margin-bottom: 12px;
  border-radius: 12px;
  border: solid 2px var(--grayscale-color-200);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.input::placeholder {
  color: var(--grayscale-color-400);
}

.input:focus-visible {
  outline: none;
}

.error {
  padding-left: 15px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: var(--danger-color);
}

.inputError {
  padding: 12px 20px;
  margin-bottom: 12px;
  border-radius: 12px;
  border: solid 2px var(--danger-color);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 1650px) {
  .container {
    padding: 100px;
  }
}

@media (max-width: 1200px) {
  .container {
    padding: 70px 60px 70px 60px;
  }
}

@media (max-width: 988px) {
  .container {
    padding: 0;
  }
}

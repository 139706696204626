.primary {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: var(--primary-color-900);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 32px;
  transition-duration: 0.3s;
  font-family: Inter;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
}

.danger {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: var(--danger-color);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 32px;
  transition-duration: 0.3s;
  font-family: Inter;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
}

.primary:disabled {
  cursor: not-allowed;
  background-color: var(--grayscale-color-200);
  color: var(--grayscale-color-400);
}

.primary:hover:enabled {
  background-color: var(--primary-color-800);
}

.danger:hover:enabled {
  background-color: red;
}

.light {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  background-color: var(--primary-color-200);
  color: var(--primary-color-900);
  font-family: Inter;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  border-radius: 24px;
  cursor: pointer;
  transition-duration: 0.3s;
}

.light:hover:enabled {
  background-color: var(--primary-color-900);
  color: white;
}

.outlined {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: white;
  color: var(--primary-color-900);
  border: 1.5px solid var(--primary-color-900);
  border-radius: 32px;
  font-family: Inter;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  transition-duration: 0.3s;
}

.outlined:hover:enabled {
  background-color: var(--primary-color-900);
  color: white;
}

.outlined-gray {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: white;
  color: var(--grayscale-color-900);
  border: 1.5px solid var(--grayscale-color-300);
  border-radius: 32px;
  font-family: Inter;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  transition-duration: 0.3s;
}

.outlined-gray:hover:enabled {
  background-color: var(--grayscale-color-200);
}

.white {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: white;
  color: var(--grayscale-color-900);
  border: none;
  border-radius: 24px;
  font-family: Inter;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.5230769515037537px;
  text-align: center;
  cursor: pointer;
  transition-duration: 0.3s;
}

.large {
  padding: 14px 18px;
  font-size: 16px;
}

.medium {
  padding: 7px 16px;
  font-size: 16px;
}

.small {
  padding: 4px 8px;
  font-size: 14px;
}

.container {
    width: 100%;
    min-height: min-content;
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    padding: 12px;
    border-radius: 16px;
}

.header {
    display: grid;
    flex-grow: 1;
    gap: 10px;
    cursor: pointer;
    grid-template-columns: min-content 1fr 24px;
    align-items: center;
}

.header>p {
    color: var(--grayscale-color-900);
    font-size: 14px;
    user-select: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 16.8px */
}

.body {
    display: grid;
    grid-template-rows: 0fr;
    padding-top: 0;
    opacity: 0;
    overflow: hidden;
    transition: grid-template-rows 0.3s, padding 0.3s, opacity 0.1s;
}

.body>div{
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-height: 0;
}

.bodyIsOpen {
    opacity: 1;
    padding-top: 8px;
    grid-template-rows: 1fr;
}

.textarea {
    border-radius: 12px;
    padding: 12px 16px;
    border: 1px solid #E7E7E7;
    background: white;
    width: 100%;
    font-size: 14px;
    font-family: Inter, serif;
    min-height: 130px;
    resize: none;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.textarea:focus {
    outline: none;
}

.textarea::placeholder {
    font-family: Inter, serif;
    color: #D9D9D9;
}

.button {
    margin-left: auto;
    padding: 5px 16px;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
}

.button:disabled {
    color: white;
    background: var(--primary-color-900);
    opacity: 0.3;
}

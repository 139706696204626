.icon {
  position: relative;
}

.icon:after {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  border-radius: 50%;
  animation: pulse-white 2s infinite;
  z-index: 0;
}

.svgIcon {
  animation: sizeSVG 2s infinite;
  border-radius: 50%;
}

.iconActive {
  position: relative;
}

.iconActive:after {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  border-radius: 50%;
  animation: pulse-purple 2s infinite;
  z-index: 0;
}

@keyframes sizeSVG {
  0% {
    transform: scale(1.05);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.05);
  }
}

@keyframes pulse-purple {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--primary-color-400);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 5px 15px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--primary-color-200);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 5px 15px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

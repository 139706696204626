.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  width: fit-content;
}

.container > div > * {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.shadow {
  height: 100%;
  width: 100%;
  background: url("../../images/purpleBackground.svg");
  background-size: cover;
  overflow-x: visible;
  opacity: 0.75;
  right: -25%;
  z-index: -1;
  position: absolute;
  pointer-events: none;
}

.button {
  width: 100%;
  font-size: 16px !important;
}

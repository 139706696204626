.container {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 3fr 3fr;
  border: 1px solid var(--primary-color-200);
  border-radius: 16px;
  padding: 12px 36px;
  align-items: center;
}

.container > div {
  font-size: 12px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}

.downloadButton {
  height: 25px;
  padding: 5px 10px;
  font-size: 12px;
}

.connectButton {
  height: 25px;
  padding: 5px 15px;
  font-size: 12px;
}

.connectButtonWrapper {
  gap: 10px;
  align-items: center;
}

.deleteButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  height: min-content;
  width: min-content;
  border: none;
}

.downloadWrapper {
  align-items: center;
  gap: 11px;
}

.downloadButtonStatus {
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.downloadButtonStatusSuccess {
  background: var(--success-color);
}

.downloadButtonStatusDanger {
  background: var(--danger-color);
}

.container {
  padding: 24px;
}

.header h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.784615px;
  margin-bottom: 8px;
}

.header p {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.784615px;
  color: rgba(21, 21, 21, 0.64);
  margin-bottom: 24px;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.container {
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
  background: white;
  max-height: 70vh;
  overflow: scroll;
  animation: slideOut 0.3s linear;
}

.containerBackground {
  z-index: 99;
  position: fixed;
  background: rgba(21, 21, 21, 0.16);
  width: 100%;
  height: 100%;
  transition: all 3s linear;
}

@keyframes slideOut {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 75%;
  }
}

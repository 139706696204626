.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  width: 100%;
  height: 100%;
  font-size: 24px;
}

.imageWrapper {
  position: relative;
  border-radius: 16px;
  background: var(--primary-color-600);
}

.gradient {
  background: var(--primary-color-900);
  border-radius: 16px;
  opacity: 0.4;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  border-radius: 16px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.questionnaireInfo {
  height: 100%;
}

.questionnaireInfo p {
  font-size: 1em;
  margin-top: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.785px;
}

.questionnaireInfo div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.questionnaireInfo div h1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 57.6px */
  letter-spacing: -0.785px;
}

.questionnaireInfo div p {
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 43.2px */
  letter-spacing: -0.785px;
  margin-bottom: 40px;
}

.nextButton {
  width: min-content;
  padding: 16px 80px;
}

@media (max-width: 988px) {
  .container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.3em;
    height: 70vh;
    grid-template-rows: 6.6em 1fr;
    font-size: calc((100vw - 320px) / (1440 - 320) * (24 - 16) + 16px);
  }

  .imageWrapper {
    margin: 0 -12px;
    border-radius: initial;
  }

  .questionnaireInfo div h1 {
    font-size: calc((100vw - 320px) / (1440 - 320) * (48 - 20) + 20px);
  }

  .questionnaireInfo p {
    font-size: calc((100vw - 320px) / (1440 - 320) * (24 - 14) + 14px);
  }

  .nextButton {
    padding: 8px 0;
    width: 100%;
  }
}

.container {
  display: grid;
  gap: 16px;
  grid-template-columns: auto 40%;
}
.allProfiles {
  /*min-width: 40%;*/
  max-width: 40%;
}

.buttonContainer {
  width: max-content;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 6px;
}

.buttonContainer button {
  background: var(--primary-color-900);
  border: none;
  padding: 4px 40px;
  border-radius: 12px;
  color: white;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  cursor: pointer;
}

.wrapTemplates {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
@media (max-width: 988px) {
  .container {
    display: flex;
    padding: 0 8px;
    flex-direction: column;
    justify-content: center;
  }
  .allProfiles {
    min-width: inherit;
    max-width: inherit;
  }
  .wrapTemplates {
    order: 2;
  }
}

.nextButton {
  display: flex;
  padding: 16px 16px;
  border-radius: 66px;
  align-items: center;
  box-shadow: 0 0px 64px -20px rgba(97, 0, 255, 0.39);
  justify-content: space-between;
  margin: 0 20px;
}

.nextButton p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.784615px;
  color: var(--primary-color-900);
}

.containerWrapper {
  height: 100%;
  container-type: size;
  container-name: questionnaire;
}

@container questionnaire (width) {
  .container {
    --questionnaire-width: clamp(300px, 100cqw, 1018px);
  }
}

.container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  gap: 1em;
  font-size: calc((var(--questionnaire-width) - 320px) / (1018 - 320) * (16 - 10) + 10px);
}

.header {
  grid-row: 1;
}

.bodyWrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.body {
  overflow: scroll;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  place-items: center;
}

.footer {
  grid-row: 3;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 2em;
}

@media (max-width: 988px) {
  .container {
    padding: 0 0;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  .inputWrapper {
    margin: auto;
  }

  .footer {
    padding-bottom: 2.5em;
    margin-top: initial;
    flex-wrap: wrap-reverse;
  }

  .footer > div {
    margin: auto;
  }

  .footer > ul {
    margin: auto;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.container {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 50%;
  height: 100%;
  max-width: 1800px;
  width: 100%;
  transform: translateX(-50%);
}

.overlay {
  position: fixed;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  top: 0;
  left: 0;
  z-index: 1000;
  cursor: pointer;
}

.overflowhidecs {
  height: 100dvh !important;
  overflow-y: hidden !important;
}

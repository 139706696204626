.layout {
  z-index: 100000;
  position: fixed;
  pointer-events: none;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 1800px;
}

.layout * {
  pointer-events: auto;
}

@media (max-width: 1800px) {
  .layout {
    width: 100%;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  gap: 18px;
  width: fit-content;
  padding: 25px 31px;
  border-radius: 20px;
  max-width: 400px;
  animation: fade 3s linear;
  background: var(--success-color-light);
}

.header {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: var(--grayscale-color-800);
}

@media (max-width: 988px) {
  .container {
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  4%,
  96% {
    opacity: 1;
  }
}

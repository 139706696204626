.container {
  font-family: "Inter", serif;
  background: #ffffff;
  width: 371px;
  border-radius: 16px;
  box-shadow: 0px 50px 53px rgba(97, 0, 255, 0.08);
  animation: 0.1s fadeIn linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

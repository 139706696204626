.inputWrapper {
  position: relative;
  width: 100%;
  opacity: inherit;
}

.input {
  padding: 12px 20px;
  border: 1px solid rgba(21, 21, 21, 0.08);
  border-radius: 48px;
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 48px;
}

.input[type="date"]::-webkit-inner-spin-button,
.input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.input::placeholder {
  color: var(--grayscale-color-400);
}

.input::-ms-reveal {
  display: none;
}

.input:focus-visible {
  outline: none;
}

.errorMessage {
  position: absolute;
  padding-left: 24px;
  margin-top: 4px;
  color: var(--danger-color);
  font-size: 12px;
  line-height: 150%;
}

.error {
  border: solid 2px var(--danger-color);
}

.visibleButton {
  background: inherit;
  width: min-content;
  height: min-content;
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0;
  transform: translateY(50%);
  cursor: pointer;
}

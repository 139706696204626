.form {
  margin-bottom: 20px;
  min-width: 375px;
  min-height: 1000px;
  padding: 24px 32px;
  background: #f2f2f2;
  border-radius: 16px;
  scroll-margin-top: 10px;
}

.subQuestion {
  color: var(--grayscale-color-500);
  font-size: 12px;
  font-weight: 300;
}

.answer {
  word-break: break-all;
}
@media (max-width: 988px) {
  .form {
    padding: 24px 12px;
  }
}

.nextButton {
  display: flex;
  padding: 16px 16px;
  border-radius: 66px;
  align-items: center;
  box-shadow: 0 0px 64px -20px rgba(97, 0, 255, 0.39);
  justify-content: space-between;
  margin: 0 20px;
}

.nextButton p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.784615px;
  color: var(--primary-color-900);
}

.form__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form__header__heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.button {
  width: 91px;
  height: 25px;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  border-radius: 12px;
}

.section {
  margin: 25px 0;
  position: relative;
  scroll-margin-top: 20px;
}

.section__title {
  color: #6100ff;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 0;
}

.block:last-child {
  border: none;
}

.question {
  color: #151515;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

.inputView {
  padding: inherit;
  border: inherit;
  color: #151515;
  font-size: 16px;
  font-style: normal;
  height: inherit;
  font-weight: 500;
  line-height: 150%;
}

.checkbox {
  background: rgba(97, 0, 255, 0.06);
  border-radius: 16px;
  padding: 16px 24px;
}

.checkboxText {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.784615px;
  color: var(--primary-color-900);
}

.openAnswer {
  background: rgba(255, 255, 255, 0.4);
  border: 2px solid var(--grayscale-color-200);
  border-radius: 8px;
  height: 32px;
  padding-left: 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

.openAnswer:focus-visible {
  outline: 0 none;
  border: 2px solid var(--primary-color-900);
}

.container {
  background-image: url("../../../../images/motherAndChildBackground.svg");
  background-repeat: no-repeat;
  background-size: 347px;
  background-position-x: -5px;
  background-position-y: -77px;
  height: 130px;
  width: 100%;
  flex-shrink: 0;
  padding: 32px 32px 0 32px;
  display: flex;
  border-radius: 24px;
  justify-content: space-between;
}

.logos {
  padding-left: 8px;
  transform-origin: top;
  scale: 1.15;
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.novabiomLogo {
  padding-left: 5px;
  transform-origin: left;
  scale: 0.8;
}

.logos a {
  width: min-content;
}

.buttons {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

@media (max-width: 988px) {
  .container {
    border-radius: initial;
    width: 347px;
  }
}

.checkBox {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  margin: 4px 0;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  font-style: normal;
  font-weight: 400;
  flex-direction: row;
  align-items: center;
  line-height: 120%;
}

.checkBox input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.text {
  word-break: break-word;
}

.checkBox .radiomark {
  position: absolute;
  /*top: 10%;*/
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: calc(20px - 16.36px) solid var(--primary-color-900);
  border-radius: 50%;
}

.checkBox .checkboxmark {
  position: absolute;
  /*top: 10%;*/
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid var(--grayscale-color-300);
  border-radius: 30%;
}

/*.checkBox:hover input ~ .checkboxmark {*/
/*    background-color: white;*/
/*}*/

/* When the radio button is checked, add a blue background */
.checkBox input:checked ~ .checkboxmark {
  content: url("../../images/checkmark.svg");
  background-color: var(--primary-color-900);
  border: 1.82px solid var(--primary-color-900);
  padding: 3px 3px 3px 3.5px;
}

.checkBox input ~ .radiomark {
  border: 1.82px solid var(--primary-color-900);
  background-color: transparent;
}

/*.checkBox:hover input ~ .radiomark {*/
/*    background-color: white;*/
/*}*/

/* When the radio button is checked, add a blue background */
.checkBox input:checked ~ .radiomark {
  /*background-color: var(--primary-color-900);*/
}

.checkBox input:checked ~ .radiomark .circle {
  position: absolute;
  background-color: var(--primary-color-900);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

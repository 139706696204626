.container {
  width: 840px;
  padding: 151px 151px 45px 151px;
  background: white;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 200px 0 rgba(97, 0, 255, 0.24);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}

.salute {
  transform: translate(-50%, -100%);
  left: 50%;
  top: 130px;
  position: absolute;
}

.container h1 {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin-bottom: 8px;
}

.container p {
  text-align: center;
  color: var(--grayscale-color-500);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-bottom: 24px;
}

@media (max-width: 988px) {
  .container {
    position: absolute;
    width: 100%;
    transform: initial;
    left: initial;
    top: initial;
    padding: 120px 24px 32px 24px;
    bottom: 0;
    border-radius: 24px 24px 0 0;
  }

  .salute {
    top: 110px;
  }
}

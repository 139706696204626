.container {
  width: fit-content;
  height: fit-content;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.container a {
  text-decoration: none;
}

.scrollWrapper {
  overflow: hidden;
  height: 80vh;
  border-radius: 24px;
  box-shadow: 0 4px 48px 0 rgba(67, 0, 176, 0.12);
}

.testPicker {
  background-color: white;
  box-shadow: 0px 4px 48px 0px #4300b01f;
  max-width: 500px;
  padding: 32px 24px;
  border-radius: 24px;
  height: 80vh;
  overflow-y: auto;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.header h1 {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.7846153974533081px;
  color: #151515;
}

.header p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.7846153974533081px;
  color: rgba(21, 21, 21, 0.64);
}

.warning {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.warning p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #6100ff;
}

.headerContainer {
  display: flex;
}

.newTest {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  border: 2px dashed rgba(97, 0, 255, 0.08);
  background: rgba(97, 0, 255, 0.04);
}

.newTestText {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.newTest h1 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #6100ff;
}

.newTest p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: rgba(97, 0, 255, 0.64);
}

.recentTestsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.recentTest {
  display: flex;
  padding: 16px 24px;
  border-radius: 16px;
  background: url("../../components/TestStatus/assets/testBg.webp");
  background-color: #6100ff;
  background-size: contain;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  cursor: pointer;
}

.recentTestText h1 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: white;
}

.recentTestText p {
  color: rgba(255, 255, 255, 0.64);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.recentTest img,
.recentTestInactive img {
  margin-left: auto;
}

.recentTestInactive {
  display: flex;
  padding: 16px 24px;
  border-radius: 16px;
  background: rgba(97, 0, 255, 0.04);
  align-items: center;
  gap: 16px;
  align-self: stretch;
  opacity: 0.4;
  cursor: pointer;
}

.recentTestInactive h1 {
  color: #6100ff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.recentTestInactive p {
  color: rgba(97, 0, 255, 0.64);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.bindTest {
  margin-top: 16px;
  color: rgba(21, 21, 21, 0.64);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.785px;
  margin-bottom: -16px;
}

.bindTest span {
  color: var(--primary-color-900);
  font-weight: 500;
  cursor: pointer;
}

@media (max-width: 600px) {
  .scrollWrapper {
    height: 100vh;
  }
  .testPicker {
    width: 100vw;
    height: 100vh;
  }
}

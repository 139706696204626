.cart {
  position: relative;
  cursor: pointer;
}

.cartLabel {
  width: 18px;
  height: 18px;
  border-radius: 16px;
  background: var(--primary-color-900);
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: Inter;
  padding-top: 0.5px;
  font-size: 10px;
  font-weight: 800;

  text-align: center;
  color: #ffffff;
  border: solid 2px white;
}

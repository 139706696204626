.gradient {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.gradient::before {
  z-index: 1;
  content: "" !important;
  position: absolute !important;
  width: 100%;
  height: 100%;
  left: 0 !important;
  top: 0 !important;
  opacity: 1 !important;
  background: linear-gradient(0deg, #6100ff 0%, #6100ff 100%) !important;
  mix-blend-mode: soft-light !important;
}

.image {
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: sticky;
  top: 0;
  align-self: start;
}

.stickyBottom {
  bottom: 10px;
  align-self: end;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.scroll {
  padding: 0 8px;
  max-height: 80vh;
  overflow-y: auto;
}

.linkBlock {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.linkText {
  position: relative;
  z-index: 100;
}

@media (max-width: 988px) {
  .container {
    z-index: 1;
  }
  .sections {
    padding-bottom: 200px;
  }
  .scroll {
    max-height: 100vh;
    transition: max-height 0.5s;
    /*overflow: hidden;*/
    height: 100vh;
    overflow: scroll;
  }
  .scroll.hidden {
    max-height: 0;
    transition: max-height 0.5s;
    overflow: hidden;
  }
  .container {
    position: sticky;
    width: 100%;
    background: white;
    align-self: start;
    top: 107px;
  }

  .header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/*.header h2 {*/
/*  font-weight: 600;*/
/*  font-size: 18px;*/
/*  line-height: 120%;*/
/*  letter-spacing: -0.784615px;*/
/*  margin-bottom: 18px;*/
/*}*/

/*.header p {*/
/*  font-family: "Inter";*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-size: 16px;*/
/*  line-height: 180%;*/
/*  color: rgba(21, 21, 21, 0.8);*/
/*}*/

/*.header span {*/
/*  color: var(--primary-color-900);*/
/*}*/

.button {
  overflow: hidden;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: left;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(97, 0, 255, 0.04), rgba(97, 0, 255, 0.04)),
    linear-gradient(82.31deg, rgba(255, 255, 255, 0.4352) -0.5%, rgba(255, 255, 255, 0.4096) 215.51%);
  border: 2px solid transparent;
}

.button:hover {
  cursor: pointer;
}

.selectedButton {
  border-radius: 16px;
  background: linear-gradient(82.31deg, rgba(255, 255, 255, 0.4352) -0.5%, rgba(255, 255, 255, 0.4096) 215.51%);
  border: 2px solid var(--primary-color-900);
}

.buttonInner {
  white-space: nowrap;
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: auto 70px;
  padding: 24px;
  color: var(--grayscale-color-900);
}
.sectionName {
  z-index: 100;
}
.totalResult {
  position: relative;
  background: rgba(97, 0, 255, 0.16);
  border-radius: 42px;
  height: 17px;
}
.header .title {
  color: #151515;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.header .percent {
  color: #6100ff;
}
.totalResult span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;

  color: white;
  z-index: 100;
  position: absolute;
  width: 100%;
  text-align: center;
}
.result {
  position: absolute;
  z-index: 1;
  height: 17px;
  background: linear-gradient(91.66deg, #6100ff -15.11%, #7825ff 87.22%), #6100ff;
  border-radius: 42px;
}
.buttonInner .chartWrapper {
  position: relative;
  height: min-content;
}

.buttonInner .chart {
  top: 10px;
  left: -4px;
  scale: 1.15;
  position: absolute;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.container {
  width: 100%;
  display: flex;
  font-size: 16px;
}

.answers {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: calc((100vw - 20em) / (90 - 20) * (6 - 1) + 1em);
}

.answer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
}

.answer:hover {
  cursor: pointer;
}

.answer span {
  color: var(--grayscale-color-900);
  font-weight: 500;
  margin-top: 1.5em;
  font-size: 1em;
  text-align: center;
}

.circle {
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  outline: 0.375em solid var(--primary-color-400);
  background: white;
}

.selectedCircle {
  z-index: 1;
  position: absolute;
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  scale: 1.2;
  background: white;
  top: calc(0.375em / -4);
  outline: 0.375em solid var(--primary-color-900);
}

.line {
  z-index: -1;
  position: absolute;
  height: 0.375em;
  background: var(--primary-color-400);
  top: calc(0.375em / 4) !important;
}

.container {
  display: flex;
  z-index: 2;
  max-width: 450px;
  min-width: 450px;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  /*margin: 12px;*/
  border-radius: 16px;
  position: relative;
  height: 100%;
}

.step {
  z-index: 2;
}
.container.containerWrap .container {
  display: flex;
  flex-direction: column;
}
.title {
  color: #fff;
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.linTest {
  color: rgba(255, 255, 255, 0.64);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  margin: 40px 0;
  position: relative;
}
.linTest:after {
  content: "";
  width: 100px;
  height: 1px;
  position: absolute;
  /*left: 112px;*/
  margin-left: 10px;
  /*right: -20px;*/
  top: 9px;
  background: rgba(255, 255, 255, 0.64);
}
.linTest:before {
  content: "";
  width: 100px;
  height: 1px;
  position: absolute;
  /*left: 112px;*/
  left: -111px;
  /*right: -20px;*/
  top: 9px;
  background: rgba(255, 255, 255, 0.64);
}
@media screen and (max-width: 1310px) {
  .container {
    padding-top: 20px;
    flex-direction: column-reverse;
    align-items: center;
    height: 390px;
    margin: inherit;
    max-width: inherit;
    width: 100%;
  }
}
.buttonBlue {
  border-radius: 32px;
  background: #6100ff;
  height: 48px;
  padding: 8px 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  color: #fff;
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
}

.linkSubmit {
  text-decoration: none;
}
.buttonBlue:active,
.buttonBlue:hover {
  text-decoration: none;
}
.needCourierContainer {
  /*display: grid;*/
  display: flex;
  /*justify-content: flex-end;*/
  flex-direction: column;
  grid-template-columns: 25% 50% 25%;
  /*height: 300px;*/
  justify-content: center;
  width: 100%;
  height: 100%;
  /*overflow: hidden;*/
}

.needCourierContainer img {
  place-self: end center;
  overflow: hidden;
}

.withoutTestsContainer {
  place-self: center;
}
.wrapImg {
  min-height: 460px;
}
.courierMap {
  height: 100%;
  width: 380px;
  place-self: end !important;
}

.courierFlask {
  place-self: end !important;
}

.courierText {
  place-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* z-index: 1; */
  /*overflow: hidden;*/
  z-index: 1;
}

.courierText h3 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 15px;
}

.courierText span {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: var(--primary-color-450);
  width: 300px;
}

.pickers {
  gap: 8px;
  display: flex;
}

.courierDateSelector {
  display: flex;
  gap: 8px;
}

.submitButton {
  width: 340px;
  background: white;
  border: 1px solid white;
}

.needCourierText {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: white;
}

.containerWithoutTests .info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: min-content;
  margin-bottom: 24px;
}

.containerWithoutTests .info p {
  white-space: nowrap;
}

@media (max-width: 988px) {
  .containerWithoutTests .info p {
    font-size: 16px !important;
    white-space: inherit;
  }
}

.containerWithoutTests .info span {
  white-space: nowrap;
}

.container h2 {
  margin-bottom: 34px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.392308px;
  color: #ffffff;
}

.container .info p {
  color: #ffffff;
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.container .info p span {
  margin-left: -10px;
  margin-right: 10px;
}

.info {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: var(--primary-color-450);
}

.chart {
  position: relative;
}

.inputWrapper {
  position: relative;
}

.checkmark {
  background: transparent;
  width: min-content;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  position: absolute;
}

.error {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  margin-top: 8px;
  color: var(--danger-color);
}

.input {
  width: 265px;
  border-radius: 46px;
  letter-spacing: 0.05cm;
}

.input::placeholder {
  letter-spacing: normal;
}

@media (max-width: 1350px) {
  .courierMap {
    width: 350px;
  }
}

@media (max-width: 1200px) {
  .courierMap {
    place-self: end center !important;
  }
  .wrapImg {
    min-height: 60%;
  }
  .courierFlask {
    place-self: end start !important;
  }
}
@media (max-width: 1310px) {
  .wrapImg {
    min-height: 60%;
  }
}

@media (max-width: 1100px) and (min-width: 988px) {
  .needCourierContainer {
    grid-template-columns: 15% 70% 15%;
  }
  .chemistry {
    width: 200px;
  }
}
@media screen and (max-width: 988px) {
  .containerWithoutTests .info p {
    font-size: 16px !important;
    white-space: inherit;
  }
  .courierMap {
    place-self: center !important;
    width: 300px;
  }

  .container {
    /*margin-right: -12px;*/
    /*margin-left: -12px;*/
  }
  .courierText {
    margin-top: 32px;
  }
}

@media (max-width: 700px) {
  .courierMobile {
    width: 110px;
  }
  .courierTruck {
    width: 300px;
  }
  .chemistry {
    visibility: hidden;
  }
  .withoutTestsContainer {
    width: 350px;
  }
  .courierFlask {
    width: 120px;
  }
  .container {
    min-width: inherit;
  }
}

.container {
  display: grid;
  height: 100%;
  grid-template-rows: min-content 1fr min-content;
}

.tableSection {
  position: relative;
}

.tableSection > div {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: scroll;
}

.searchBarSection {
  padding-bottom: 32px;
}

.addPatientSection {
  padding-bottom: 36px;
}

.noUsersBlock {
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
}

.noUsersBlock > h2 {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.page {
  padding: 24px;
  background: linear-gradient(202.74deg, rgba(255, 255, 255, 0.8) 4.57%, rgba(255, 255, 255, 0) 77.58%);
  border-radius: 24px;
  min-height: 100%;
}

.menu {
  margin: 16px 0;
  padding: 24px;
  background: linear-gradient(202.74deg, rgba(255, 255, 255, 0.8) 4.57%, rgba(255, 255, 255, 0) 77.58%);
  border-radius: 24px;
  min-height: 100%;
}

.buttonWrapper {
  display: flex;
  justify-content: right;
}

.leaveButton {
  padding-right: 18px;
  padding-left: 18px;
  border-radius: 47px;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  height: 48px;
}

.popUp {
  position: absolute;
  bottom: 10%;
  right: 10%;
}

.delete {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 988px) {
  .buttonWrapper {
    margin: 0 auto;
    max-width: 637px;
    justify-content: left;
    margin-bottom: 32px;
  }

  .leaveButton {
    height: 32px;
  }
}

.container {
  display: flex;
  justify-content: space-around;
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: url("../assets/bg.jpg");
  background-size: cover;
}

.mainSection {
  display: flex;
  flex-direction: column;
  margin-top: 215px;
}

.header {
  font-family: Inter;
  font-size: 48px;
  font-weight: 500;
  max-width: 700px;
  padding-bottom: 24px;
  color: white;
}

.secondaryText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
  width: 600px;
  color: white;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  border-radius: 16px;
  margin: 24px 0;
  background: white;
  width: 661px;
}

.logo {
  width: 350px;
  margin-top: 50px;
}

@media (max-width: 1400px) {
  .form {
    width: 551px;
  }
}

@media (max-width: 1300px) {
  .container {
    gap: 0;
  }
  .header {
    width: 500px;
  }
  .secondaryText {
    width: 500px;
  }
}

@media (max-width: 988px) {
  .container {
    justify-content: center;
    padding: 25px 0;
    background: none;
  }
  .form {
    width: 100%;
    padding: 0 150px;
  }
  .background {
    display: none;
  }
}

@media (max-width: 988px) {
  .container {
    justify-content: center;
    padding: 25px 0;
  }
  .form {
    width: 100%;
    padding: 0 50px;
  }
}

@media (max-width: 500px) {
  .form {
    padding: 0 10px;
  }
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.inputWrapper {
  min-width: 18.75em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9em;
  border-radius: 1.8em;
  border: 0.1em solid #6100ff;
  font-size: 1em;
  background: linear-gradient(180deg, #f9f5ff 0%, #f9f5ff 100%);
}

.input {
  font-size: clamp(16px, 1.125em, 18px);
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  letter-spacing: -0.785px;
  color: var(--primary-color-900);
  background: none;
  border: none;
  text-align: center;
}

.input::placeholder {
  color: var(--primary-color-600);
}

.input:focus {
  outline: none;
}

.pickerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2em;
  position: relative;
}

.backgroundIcon {
  z-index: -1;
  position: absolute;
  left: 6%;
  top: 15%;
  scale: 1.2;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-repeat: no-repeat;
  width: 13.3em;
  height: 13.3em;
}

@media (max-width: 988px) {
  .inputWrapper {
    font-size: calc((100vw - 320px) / (1440 - 320) * (18 - 14) + 14px);
  }
}

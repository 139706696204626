.container {
  z-index: 1;
}

.overlay {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  top: 0;
  left: 0;
  position: absolute;
}

.container {
  position: relative;
  width: 100%;
}

.pdfWrapper {
  padding-top: 15px;
  position: relative;
  height: 95vh;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  flex-direction: column;
  align-items: center;
}

.pdf {
  transform-origin: top;
  position: relative;
  width: 595px;
  transition: all 0.3s linear;
  margin-bottom: 20px;
}

@media (max-width: 988px) {
  .pdf {
    width: 100%;
  }
}

.pdfWrapper::-webkit-scrollbar {
  width: 8px;
  scroll-behavior: smooth;
  height: 0;
}

.pdfWrapper::-webkit-scrollbar-track {
  background: var(--primary-color-200);
  border-radius: 37px;
}

.pdfWrapper::-webkit-scrollbar-thumb {
  background: var(--primary-color-850);
  height: 85px;
  box-shadow: 0px 0px 8px rgba(97, 0, 255, 0.4);
  border-radius: 37px;
}

.pdfWrapper {
  scroll-behavior: smooth;
  scrollbar-color: #6100ff var(--primary-color-250);
}

@media (max-width: 720px) {
  .pdfWrapper::-webkit-scrollbar {
    display: none;
  }

  .pdfWrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.error {
  color: var(--danger-color) !important;
}

.pdf canvas {
  width: 100% !important;
  height: auto !important;
}

.documentPdf {
  border-radius: 24px;
  overflow: hidden;
  background: white;
  animation: fadeIn 1s linear;
  padding-bottom: 200px;
  border: 1px solid #f4f6f6;
}

.documentPdfLoaded {
  box-shadow: 0px 0px 80px rgba(21, 21, 21, 0.04);
}

.pdfWrapperNotLoaded {
  height: 95vh;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.notLoadedPage {
  opacity: 0;
}

.pageLoaded {
  opacity: 1;
  animation: fadeIn 1s linear;
  width: 100%;
}

.loader {
  box-shadow: 0px 0px 80px rgba(21, 21, 21, 0.04);
  border-radius: 24px;
  background: white;
  z-index: 9;
  position: absolute;
  height: 100vh;
  border: 1px solid #f4f6f6;
  width: 100%;
  animation: fadeIn 0.3s;
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
}

.loader p {
  padding-left: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.3px;
  margin-top: 340px;
}

.loader p span {
  color: var(--primary-color-900);
}

.loaderFadeOut {
  opacity: 0;
  animation: fadeOut 0.3s;
}

.zoomButtons {
  z-index: 10;
  position: absolute;
  right: 16px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 40px;
  gap: 4px;
}

.downloadButton {
  height: 40px;
  width: 40px;
  border-radius: 16px;
  margin-bottom: 10px;
}

.zoomButton {
  font-size: 40px;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 16px;
  padding: 0;
}

@media (max-width: 1200px) {
  .zoomButton {
    display: none;
  }
  .downloadButton {
    position: fixed;
    right: 16px;
    bottom: 10px;
    z-index: 100;
    background-color: var(--primary-color-500);
  }
}

.zoomButton * {
  padding: 9px 12px 14px 12px;
}

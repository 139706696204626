.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  min-width: 18.75em;
  text-align: center;
  padding: 0.89em 2.7em;
  border-radius: 1.8em;
  border: 0.1em solid var(--primary-color-900);
  background: linear-gradient(180deg, #f9f5ff 0%, #f9f5ff 100%);
  font-style: normal;
  font-size: clamp(16px, 1.125em, 18px);
  font-weight: 500;
  line-height: 150%; /* 27px */
  letter-spacing: -0.04361rem;
  color: var(--primary-color-900);
}

.inputWrapper {
  position: relative;
  width: max-content;
}

.icon {
  position: absolute;
  left: 0.89em;
  transform: translateY(-50%);
  background-size: cover;
  top: 50%;
  background-repeat: no-repeat;
  width: 1.7em;
  height: 1.7em;
}

.backgroundIcon {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-repeat: no-repeat;
  width: 13.3em;
  height: 13.3em;
}

.textareaWrapper {
  font-size: 1em;
  width: clamp(300px, 32.5em, 520px);
}

.textarea {
  font-family: Inter, sans-serif;
  width: 100%;
  min-height: 200px;
  padding: 24px 24px;
  border-radius: 32px;
  border: 2px solid var(--primary-color-900);
  background: linear-gradient(180deg, #f9f5ff 0%, #f9f5ff 100%);
  font-size: 1.125em;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  letter-spacing: -0.785px;
  color: var(--primary-color-900);
  resize: none;
}

.textarea::placeholder {
  font-family: Inter, sans-serif;
  color: var(--primary-color-600);
}

.textarea:focus {
  outline: none;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: var(--primary-color-600);
}

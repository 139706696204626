.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  gap: 18px;
  width: fit-content;
  padding: 25px 31px;
  border-radius: 20px;
  max-width: 400px;
  animation: fade 3s linear;
}

.container-error {
  background: var(--danger-color-light);
}

.container-success {
  background: var(--success-color-light);
}

.container p {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: var(--grayscale-color-800);
}

@media (max-width: 988px) {
  .container {
    width: 90%;
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  4%,
  96% {
    opacity: 1;
  }
}

.container {
  padding: 0 16px;
}

@media (max-width: 988px) {
  .container {
    padding: 0;
    margin: 0 -20px 0 -20px;
  }
}

.downloadButton {
  position: absolute;
  right: 5%;
  bottom: 20px;
  z-index: 100;
}

.container {
  font-size: 16px;
  display: flex;
  gap: 0.75em;
}

@media (max-width: 988px) {
  .container {
    font-size: calc((100vw - 320px) / (1440 - 320) * (16 - 10) + 10px);
  }
}

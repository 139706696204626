/* DenialResponsibility.module.css */

.modal {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  z-index: 100000;
  background-color: rgb(248 248 248 / 50%);
}

.modalShow {
  display: flex;
}

.modalContent {
  display: flex;
  box-shadow: 0 4px 200px 0 rgba(97, 0, 255, 0.24);
  align-items: center;
  background-color: #fff;
  max-width: 684px;
  gap: 24px;
  border-radius: 24px;
  padding: 32px;
  text-align: center;
  z-index: 100000;
}

.modalTitle {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.7846153974533081px;
  text-align: left;

  margin-bottom: 16px;
}

.closeButton {
  margin-top: 32px;
}
.body {
  display: flex;
  gap: 16px;
  flex-direction: column;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  color: #151515a3;
  line-height: 25px;
  letter-spacing: -0.7846153974533081px;
  text-align: left;
}
@media screen and (max-width: 739px) {
  .imgBlock {
    width: 100%;
  }
  .imgBlock img {
    width: 100%;
    height: 100%;
  }
  .modalContent {
    justify-content: center;
    flex-direction: column;
    max-width: inherit;
    border-radius: inherit;
    height: 100%;
    overflow-y: scroll;
    box-shadow: inherit;
  }
  .modal {
    height: calc(100% - 76px);
    top: inherit;
    bottom: 0;
  }
  .closeButton {
    width: 100%;
  }
}

.gridContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  place-items: center;
}

.gridContainer > button:nth-child(odd):last-child {
  grid-column: span 2;
}

.flexContainer {
  width: 100%;
  display: flex;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
}

.container {
  padding: 160px;
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
}

.header {
  font-family: Inter;
  font-size: 28px;
  font-weight: 700;
  line-height: 31px;
  margin-bottom: 24px;
  color: var(--grayscale-color-900);
}

.secondaryText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 340px;
  margin-bottom: 40px;
  color: var(--grayscale-color-600);
}

.input {
  margin-bottom: 12px;
}

.loginSettings {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  padding-top: 15px;
  gap: 8px;
  margin: 26px 0 42px 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.39230769872665405px;
  color: var(--grayscale-color-600);
}

.checkbox {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.392308px;
}

.forgotPassword {
  cursor: pointer;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.39230769872665405px;
  color: var(--primary-color-900);
  text-decoration: none;
  min-width: max-content;
}

.signIn {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 1%;
}

.signText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.39230769872665405px;
  color: var(--primary-color-900);
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 1650px) {
  .container {
    padding: 100px;
  }
}

@media (max-width: 1200px) {
  .container {
    padding: 70px 60px 70px 60px;
  }
}

@media (max-width: 988px) {
  .container {
    padding: 0;
  }
}

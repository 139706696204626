.container {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    background: none;
    border: none;
    cursor: pointer;
}

.icon {
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
}

.headerContainer {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 42px;
}

.headerContainer h3 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  color: var(--grayscale-color-900);
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 637px;
  gap: 32px;
}

.form label {
  font-weight: 500;
  width: 100%;
  display: grid;
  grid-template-columns: min-content 372px;
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: space-between;
}

.form label p {
  width: 132px;
  white-space: nowrap;
}

/*.labelInput{*/
/*  display: grid;*/
/*  grid-template-columns: min-content auto 372px;*/
/*}*/

.buttons {
  max-width: 637px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deleteButton {
  white-space: nowrap;
  width: min-content;
  padding-right: 16px;
  padding-left: 16px;
}

.buttons .saveButton {
  height: 48px;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 41px;
  width: min-content;
}

.avatarWrapper {
  display: flex;
  justify-content: left;
  gap: 20px;
  align-items: center;
  width: 372px;
}

.input {
  border: 1px solid rgba(21, 21, 21, 0.08);
  border-radius: 48px;
}

.select {
  appearance: none;
  background-color: transparent;
  padding: 6.5px 23px;
  margin: 0;
  font-family: Inter, serif;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  border-radius: 48px;
  outline: none;
  border: 1px solid rgba(21, 21, 21, 0.08);
}

.genderSelector {
  width: 372px;
}

.selectWrapper {
  width: min-content;
  position: relative;
}

.downArrow {
  position: absolute;
  right: 15px;
  top: 42%;
}

@media (max-width: 988px) {
  .form {
    margin: 0 auto;
  }

  .buttons {
    gap: 24px;
    flex-direction: column;
  }

  .form label {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .form .buttons .saveButton {
    order: -1;
    width: 100%;
  }

  .form .buttons .deleteButton {
    border-radius: 43px;
    width: 100%;
    height: 48px;
  }
}

.uploadButton {
  height: 32px;
  width: 132px;
}

.deleteAvatarButton {
  background: white;
  width: 32px;
  height: 32px;
  border-radius: 12px;
  border: 2px solid rgba(21, 21, 21, 0.04);
}

.avatar {
  object-fit: cover;
  width: 48px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.container {
  padding: 1em 1em 0 1em;
  width: 21.875em;
  background: white;
  box-shadow: 0 0.25em 2em 0 rgba(97, 0, 255, 0.16);
  border-radius: 1em;
  height: min-content;
}

.container .header {
  font-size: 1.25em;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 130% */
  display: flex;
  justify-content: space-evenly;
}

.slider {
  gap: 0.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.slider .month {
  text-align: center;
  min-width: 4.8em;
}

.arrowButton {
  background: none;
  border: none;
}

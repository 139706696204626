.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.header {
    position: sticky;
    z-index: 1;
    top: 0;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(92deg, #995FF6 -15.11%, #7825FF 87.22%), #6100FF;
    box-shadow: 0 10px 16px -9px rgba(97, 0, 255, 0.39);
}

.header>p {
    color: white;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter, "sans-serif";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
}

.button {
    padding: 5px 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.button:disabled {
    opacity: 0.2;
}

.container {
  position: absolute;
  width: 697px;
  height: 671px;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background: white;
  padding: 24px;
  box-shadow: 0 4px 200px 0 rgba(97, 0, 255, 0.24);
  border-radius: 24px;
}

.questionnaire {
  height: 100%;
}

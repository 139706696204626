.container {
  width: 100%;
  font-size: 1em;
}

.progress {
  width: 100%;
  height: 0.5em;
  position: relative;
  background: var(--primary-color-250);
  border-radius: 0.25em;
  margin-bottom: 1.5em;
}

.top {
  margin: 1.5em 0 1.5em 0;
  display: flex;
  gap: 2em;
}

.tipIcon {
  width: clamp(24px, 2em, 32px);
  height: clamp(24px, 2em, 32px);
}

.footer {
  display: flex;
  justify-content: space-between;
}

.footer p {
  color: var(--primary-color-900);
  font-size: clamp(14px, 1.25em, 20px);
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 36px */
}

.line {
  position: absolute;
  background: var(--primary-color-900);
  border-radius: 0.25em;
  top: 0;
  height: clamp(4px, 0.5em, 8px);
}

.section {
  color: var(--primary-color-900);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.785px;
}

.question {
  font-size: clamp(20px, 2em, 32px);
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 48px */
  letter-spacing: -0.785px;
}

.menuIcon {
  background: none;
  border: none;
}

.tipButton {
  margin-left: 0.5em;
}

.menuIcon:hover {
  cursor: pointer;
}

.tipWrapper {
  position: relative;
}

.tip {
  z-index: 1;
  position: absolute;
  left: calc(0.5em + clamp(24px, 2em, 32px) + 1em);
  top: 0;
  width: clamp(250px, 22.3125em, 357px);
  padding: 0.5em 1em;
  border-radius: 0 1em 1em 1em;
  background: var(--primary-color-900);
}

.tip p {
  font-size: clamp(14px, 1em, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.785px;
  color: var(--white);
}

/*@media (max-width: 988px) {*/
/*    .question {*/
/*        font-size: 20px;*/
/*    }*/
/*    .footer p {*/
/*        font-size: 14px;*/
/*    }*/

/*    .progress {*/
/*        height: 4px;*/
/*    }*/

/*    .line {*/
/*        height: 4px;*/
/*    }*/

/*    .top {*/
/*        gap: 16px;*/
/*    }*/
/*}*/

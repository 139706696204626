.container {
  padding: 160px;
  display: flex;
  flex-direction: column;
}

.goBack {
  display: flex;
  gap: 16px;
  cursor: pointer;
}

.goBack a {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.39230769872665405px;
  color: var(--primary-color-900);
  text-decoration: none;
}

.header {
  font-family: Inter;
  font-size: 28px;
  font-weight: 500;
  line-height: 31px;
  color: var(--grayscale-color-900);
  margin: 32px 0 40px 0;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.emailHeader {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: var(--grayscale-color-800);
  margin-bottom: 12px;
}

.secondaryText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--grayscale-color-500);
  margin-bottom: 40px;
}

@media (max-width: 1650px) {
  .container {
    padding: 80px;
  }
}

@media (max-width: 1200px) {
  .container {
    padding: 70px 60px 70px 60px;
  }
}

@media (max-width: 988px) {
  .container {
    padding: 0;
  }
}

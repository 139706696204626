.loader {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
    background: white;
    transition: opacity 0.5s ease-in-out;
    z-index: 1;
}

.loaderLoaded {
    opacity: 0;
}

.loader>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
}

.loader>div>p{
    color: var(--grayscale-color-900);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 83.333% */
}

.loader>div>p>span {
    color: var(--primary-color-900);
}

.downloadButtton {
    position: sticky;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px
}

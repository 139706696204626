.container {
  width: 100%;
}

.container > h2 {
  color: var(--grayscale-color-900);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
  margin-bottom: 32px;
}

.addForm {
  align-items: end;
  display: grid;
  gap: 6px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.addForm > div > p {
  padding-left: 15px;
  font-size: 12px;
  margin-bottom: 7px;
}

.addForm > div > p > span {
  color: var(--danger-color);
}

.error {
  padding-left: 15px;
}

.formInput {
  border-radius: 16px;
  border: 1px solid rgba(97, 0, 255, 0.1);
  padding: 8px 15px;
  font-size: 14px;
  height: 33px;
}

.addButton {
  height: 33px;
}

.container {
  width: 100%;
}

.container > h2 {
  color: var(--grayscale-color-900);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
  margin-bottom: 32px;
}

.searchBarInput {
  font-size: 14px;
  height: min-content;
  border-radius: 16px;
  border: 1px solid rgba(97, 0, 255, 0.1);
  padding: 8px 52px 8px 35px;
}

.searchBarWrapper {
  position: relative;
}

.searchIcon {
  transform: translateY(-50%);
  top: 56%;
  position: absolute;
  right: 35px;
}

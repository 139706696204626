.container {
  padding: 32px 32px 8px 32px;
  display: flex;
  flex-direction: column;
}

.logosWrapper {
  gap: 42px;
  display: flex;
}

.logo {
  margin-top: -8px;
}

.orgLogoContainer {
  padding: 24px 12px 0 12px;
  background: white;
  margin-top: -32px;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  height: 75px;
  width: 200px;
}

.orgLogo {
  width: 100%;
}

@media (max-width: 988px) {
  .container {
    gap: 16px;
    width: min-content;
  }
}

.container {
  padding: 32px 32px 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  margin-top: -8px;
}

.buttons {
  display: flex;
  gap: 12px;
  align-items: center;
}

@media (max-width: 988px) {
  .container {
    gap: 16px;
    width: min-content;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container > .tableHeader {
  width: 100%;
  padding: 4px 35px;
  display: grid;
  color: var(--grayscale-color-300);
  font-size: 12px;
  margin-bottom: 5px;
  grid-template-columns: 2fr 2fr 2fr 3fr 3fr;
}

.container > .tableHeader > div {
  display: flex;
  justify-content: center;
}

.tableBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: white;
}

.header {
  display: flex;
  gap: 16px;
  align-items: center;
}

.header h1 {
  margin-bottom: 8px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
  letter-spacing: -0.785px;
}

.header p {
  color: var(--grayscale-color-500);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.785px;
}

.textarea {
  border-radius: 8px;
  padding: 12px 16px;
  border: 2px solid rgba(21, 21, 21, 0.08);
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  font-family: Inter;
  min-height: 200px;
  resize: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

.textarea:focus {
  outline: none;
}

.textarea::placeholder {
  font-family: Inter;
  color: var(--grayscale-color-400);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cleanButton {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--primary-color-900);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

.cleanButton:hover {
  cursor: pointer;
}

.footer > div {
  display: flex;
  gap: 8px;
}

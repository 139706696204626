/*@media (max-width: 988px) {*/
/*    html{*/
/*        overflow-x: hidden;*/
/*    }*/
/*}*/

body {
  font-family: "Inter", serif;
  font-style: normal;
  background: white;
  position: relative;
  overflow-x: hidden !important;
  margin: 0 auto;
}

/*@media (max-width: 988px) {*/
/*    body {*/
/*        max-width: 100vw;*/
/*        overflow: hidden;*/
/*    }*/
/*}*/

.AppLayout {
  max-width: 1800px;
  overflow-x: hidden !important;
  margin: 0 auto;
}

h1 {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 120%;
  color: var(--black);
}

h2 {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 120%;
  color: var(--black);
}

h3 {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 120%;
  color: var(--black);
}

h4 {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  color: var(--black);
}

h5 {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: var(--black);
}

#webpack-dev-server-client-overlay {
  display: none;
}

h6 {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: var(--black);
}

p {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
}

.p2 {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--black);
}

.description {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
}

hr {
  margin: 32px 0;
  height: 0;
  border: 1px solid rgba(21, 21, 21, 0.06);
}

.buttonLarge {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: var(--black);
}

.buttonMedium {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: var(--black);
}

.buttonSmall {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: var(--black);
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}

*::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 16px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color-600);
  border-radius: 16px;
}

div#carrotquest-messenger-collapsed-container {
  display: none;
}

#chat-container {
  width: min-content;
}

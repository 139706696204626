.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timerWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: center;
  position: relative;
}

.timerWrapper .dots {
  padding-bottom: 40px;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 150%; /* 60px */
  letter-spacing: -0.785px;
  color: var(--primary-color-900);
}

.caption {
  color: var(--primary-color-900);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.785px;
}

.element {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.backgroundIcon {
  z-index: -1;
  position: absolute;
  left: 6%;
  top: 25%;
  scale: 1.2;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-repeat: no-repeat;
  width: 13.3em;
  height: 13.3em;
}

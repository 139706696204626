.container {
  bottom: 0;
  width: 100%;
  position: fixed;
  background: white;
  max-height: 70dvh;
  overflow: scroll;
  animation: slideOut 0.3s linear;
}

@keyframes slideOut {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 75%;
  }
}

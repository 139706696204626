.container {
  width: 20.5625em;
  height: 17.5em;
  text-align: left;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1.5em;
  border-radius: 1.5em;
  border: none;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  letter-spacing: -0.785px;
  position: relative;
  overflow: hidden;
  font-size: 1em;
  background: linear-gradient(180deg, #f9f5ff 0%, #f9f5ff 100%);
}

.icon {
  width: 100%;
  height: 1.5em;
  background-color: var(--primary-color-900);
}

.caption {
  width: calc(100% - 2.4em);
  position: absolute;
  z-index: 100;
}

.caption span {
  display: flex;
  align-items: center;
  gap: 0.3em;
}

.answer {
  color: var(--primary-color-900);
  font-size: 1.25em;
}

.caption textarea {
  background: transparent;
  margin-top: 1.75em;
  font-size: 0.875em;
  color: white;
  border: none;
  font-family: Inter, serif;
  width: 100%;
  height: 8em;
  resize: none;
}

.caption textarea:focus {
  outline: none;
}

.caption textarea::placeholder {
  color: var(--primary-color-400);
  font-family: Inter, serif;
}

.selectedIcon {
  background-color: white;
}

.selected {
  background: url("../../../images/questionBackground.png") no-repeat left;
  background-size: cover;
}

.selected .answer {
  color: white;
}

.selected::before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10.625em;
  height: 10.625em;
  content: "";
  background: url("../../../images/transperentCheck.svg") no-repeat center;
  background-size: contain;
}

.cover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.selected .circle {
  position: absolute;
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  background: white;
  left: 1em;
  bottom: 1em;
  outline: 0.1875em white solid;
  outline-offset: 0.25em;
}

.circle {
  position: absolute;
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  background: transparent;
  left: 1em;
  bottom: 1em;
  outline: 0.1875em var(--primary-color-300) solid;
  outline-offset: 0.25em;
}

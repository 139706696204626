.container {
  display: flex;
  gap: 8px;
  border-radius: 32px;
  background: none;
  border: none;
  padding: 12px 24px 12px 16px;
  align-items: center;
  cursor: pointer;
}

.selected {
  border: 1px solid var(--primary-color-900);
  background: var(--primary-color-900);
}

.selected p {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.785px;
  color: white;
}

.unselected {
  border: 1px solid var(--primary-color-900);
  background: linear-gradient(180deg, #f9f5ff 0%, #f9f5ff 100%);
}

.unselected p {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.785px;
  color: var(--primary-color-900);
}

.icon {
  width: 24px;
  height: 24px;
}

.customDatePicker .rmdp-header {
  display: none;
}

.customDatePicker .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--primary-color-900) !important;
}

.customDatePicker .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  color: white !important;
}

.customDatePicker .rmdp-day .sd:hover {
  background-color: var(--primary-color-400) !important;
  color: var(--primary-color-900) !important;
}

.customDatePicker .rmdp-day.rmdp-today span {
  background: none;
}

.customDatePicker .rmdp-wrapper {
  width: 100%;
}

.customDatePicker .rmdp-top-class {
  width: 100%;
}

.customDatePicker .rmdp-calendar {
  width: 100%;
}

.customDatePicker .rmdp-day-picker > div {
  width: 100%;
}

.customDatePicker .rmdp-shadow {
  box-shadow: none;
}

.customDatePicker .rmdp-day {
  width: 2em;
  height: 2em;
}

.customDatePicker .rmdp-week-day {
  color: var(--grayscale-color-400);
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  line-height: 2em; /* 200% */
}

.customDatePicker .rmdp-day span {
  color: var(--grayscale-color-900);
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
}

@media (max-width: 988px) {
  .customDatePicker {
    font-size: calc((100vw - 320px) / (1440 - 320) * (16 - 14) + 14px);
  }
}

.container {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.wrapper {
  box-shadow: 0 4px 200px 0 rgba(97, 0, 255, 0.24);
  border-radius: 24px;
  background: white;
  padding: 24px;
}

.mobileWrapper {
  width: 100%;
  padding: 24px;
}

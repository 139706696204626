.containerWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  background: linear-gradient(173.38deg, #6100ff 1.26%, #7825ff 103.24%);
  min-width: 350px;
  padding-bottom: 12px;
  outline: 1px solid white;
}

.navContainer {
  position: relative;
  width: 100%;
  padding-top: 16px;
  padding-right: 32px;
  padding-left: 32px;
}

.bottomCaption {
  margin-top: 5px;
  display: flex;
  justify-self: flex-end;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}
.bottomCaption a {
  text-decoration: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
}

.logo {
  display: flex;
  flex-direction: column;
  transform-origin: left;
  scale: 1.15;
}

.headerBackground {
  top: -65px;
  left: 13px;
  scale: 1.15;
  position: absolute;
}

.headContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 45px;
}

.logoWrapper {
  display: flex;
  align-content: center;
}

.headIcons {
  padding-top: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.headIconsColumn {
  flex-direction: column-reverse;
}

.bottomNavContainer {
  position: relative;
  width: calc(100% - 32px);
  background: linear-gradient(180deg, #5b11d5 -115.33%, rgba(91, 0, 240, 0) 82.76%);
  border-top-right-radius: 32px;
  border-top: #7825ff solid 2px;
  padding-right: 0;
}

.bottomNavContainer::before {
  content: "";
  position: absolute;
  height: 50px;
  aspect-ratio: 1/1;
  border-bottom-left-radius: 32px;
  border-left: #7825ff solid 2px;
  border-bottom: #7825ff solid 2px;
  box-shadow: -23px 38px 10px #6612ed;
  transform: translateY(-100%);
  left: 0;
  top: 0;
}

.closeIcon {
  border: none;
  background: none;
  z-index: 100;
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}

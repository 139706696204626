.headIcons {
  padding-top: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.cart {
  position: relative;
  cursor: pointer;
}

.cartLabel {
  min-width: 16px;
  min-height: 16px;
  border-radius: 16px;
  background: var(--primary-color-900);
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: Inter;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

.headerContainer {
  background: white;
  display: flex;
  align-items: center;
  gap: 16px;
}

.logoWrapper {
  display: flex;
  align-content: center;
}

.container {
  background: white;
  padding: 0 16px;
  display: flex;
  min-height: 40px;
  justify-content: space-between;
}

.icon {
  cursor: pointer;
}

.container {
  margin: 16px auto 0 auto;
  min-height: calc(100vh - 16px * 2);
  max-width: 1400px;
  display: grid;
  gap: 20px;
  position: relative;
  grid-template-columns: min-content 1fr;
}

.mobileContainer {
  min-height: 100dvh;
  width: 100vw;
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: max-content;
}

.mobileContainer > main {
  padding: 10px 12px 10px 12px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.mobileContainer > header {
  padding: 10px 0;
  background: white;
  position: sticky;
  align-self: start;
  z-index: 10;
  top: 0;
  width: 100%;
}

.aside {
  border-radius: 24px;
  top: 16px;
  position: sticky;
  overflow: scroll;
  display: grid;
  max-height: calc(100vh - 16px * 2);
  width: fit-content;
}

@media (max-width: 1400px) {
  .container {
    max-width: calc(100vw - 20px * 2);
  }
}

.container main {
}

.container {
  background: none;
  display: flex;
  align-items: center;
  width: min-content;
  justify-items: center;
  padding: 0.33em 1.33em;
  height: 2.15em;
  gap: 0.67em;
  border-radius: 1.6em;
  border: 2px solid #6100ff;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.04906em;
  white-space: nowrap;
}

.container:hover {
  cursor: pointer;
}

.primary {
  background: var(--primary-color-900);
  color: white;
}

.primary:disabled {
  cursor: initial;
  border: 2px solid var(--primary-color-300);
  background: var(--primary-color-300);
  color: white;
}

.outlined {
  background: white;
  color: var(--primary-color-900);
}

.outlined:disabled {
  border: 2px solid var(--primary-color-300);
  color: var(--primary-color-300);
}

.container {
  width: min-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputWrapper {
  border-radius: 24px;
  width: min-content;
  padding: 40px 32px;
  border: 1px solid var(--primary-color-900);
  background: linear-gradient(180deg, var(--primary-color-150) 0%, var(--primary-color-150));
}

.selected {
  border: 2px solid var(--primary-color-900);
}

.unselected {
  border: 2px solid transparent;
}

.input {
  border: none;
  width: 56px;
  background: transparent;
  color: var(--primary-color-900);
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 60px */
  letter-spacing: -0.785px;
}

.input:focus {
  outline: none;
}

.button {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--primary-color-900);
  background: transparent;
  border: none;
  font-size: 24px;
}

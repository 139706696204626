.mainContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 40px;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 48px;
  animation-duration: 1000ms;
  animation-name: menu;
}

.animationContainer {
  z-index: 1000;
  width: 100%;
  flex-grow: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
}

.animation {
  width: 300px;
  animation-name: appearance;
  animation-duration: 1000ms;
}

.errorIn {
  animation-duration: 1000ms;
  animation-name: in;
}

.errorOut {
  animation-delay: 1s;
  animation-duration: 2000ms;
  animation-name: out;
}

@keyframes appearance {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes in {
  0% {
    transform: scale(0);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes out {
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  0% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes menu {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header {
  position: relative;
}

.food {
  position: absolute;
  top: -140px;
  right: -55px;
  z-index: -1;
}

.glow {
  position: absolute;
  transform: rotate(-90deg);
  top: -200px;
  width: 500px;
  left: -150px;
  z-index: -1;
}

.button {
  height: 48px;
  min-width: max-content;
}

.activeButton svg {
  animation-duration: 1000ms;
  animation-name: button;
  animation-iteration-count: infinite;
}

@keyframes button {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.header h1 {
  margin-top: 24px;
  color: #151515;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.785px;
}

.header p {
  margin-top: 10px;
  color: #151515;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  opacity: 0.6399999856948853;
  max-width: 621px;
}

.headerDescription {
  display: flex;
  margin-bottom: 12px;
}

.aiDescriptionSection {
  display: flex;
  column-gap: 20px;
  row-gap: 16px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.aiDescriptionContainer {
  background: linear-gradient(90deg, rgba(114, 33, 245, 0.14) 0%, rgba(181, 23, 23, 0.1) 100%);
  border-radius: 16px;
  position: relative;
  padding: 6px 6px 6px 60px;
  overflow: hidden;
  max-width: 621px;
  min-width: 343px;
}

.aiBackgroundImage {
  position: absolute;
  top: -17px;
  left: -21px;
}

.aiDescriptionContainer > p {
  margin-top: 0;
  font-size: 14px;
}

@media (max-width: 988px) {
  .header h1 {
    font-size: 32px;
  }
  .header p {
    font-size: 16px;
  }
  .food {
    display: none;
  }
  .glow {
    display: none;
  }
  .aiDescriptionSection {
    flex-direction: column;
  }
  .aiDescriptionContainer {
    padding: 6px 16px;
  }
  .aiDescriptionContainer > p {
    font-size: 14px;
  }

  .aiBackgroundImage {
    left: initial;
    right: -21px;
  }
}

@media (max-width: 650px) {
  .menuContainer {
    grid-template-columns: 1fr;
  }
}

.container {
    width: 100%;
    aspect-ratio: 1/1;
    height: 100%;
    position: relative;
}


.percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    font-weight: bold;
    font-family: "Inter", serif;
    transform: translate(-50%, -50%);
}

.CircleProgress {
    transform: rotate(-90deg)
}

.progressWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    aspect-ratio: 1/1;
    transform: translate(-50%, -50%);
}

.CircleProgressBar {
    stroke: #11d118;
    stroke-linecap: round; /* Округление концов линии */
}

.container {
  border-radius: 16px;
  width: 100%;
  border: none;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
  padding: 23px;
  justify-content: center;
}

.image {
  position: absolute;
  border-radius: 16px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.chartWrapper {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  backdrop-filter: blur(7px);
  position: relative;
  overflow: hidden;
}

.chart {
  top: 10px;
  left: -4px;
  scale: 1.15;
  position: absolute;
}

.container:hover {
  cursor: pointer;
}

.innerContainer {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: auto 46px min-content;
  border-radius: 12px;
}

.selected:before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 15px);
  height: calc(100% - 15px);
  z-index: 1;
  border-radius: 12px;
  border: 4px solid white;
}

.content {
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.11);
  backdrop-filter: blur(7px);
  padding: 8px 16px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 150%; /* 27px */
  letter-spacing: -0.785px;
}

.content span {
  font-weight: 400;
  font-size: 14px;
}

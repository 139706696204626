.container {
  background: linear-gradient(82.31deg, rgba(255, 255, 255, 0.68) -0.5%, rgba(255, 255, 255, 0.64) 215.51%);
  padding: 16px 24px;
  border: 1px solid #f0f4ff;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
}

.cancelContainer {
  background: rgba(238, 72, 72, 0.08);
  border-radius: 16px;
  padding: 16px 24px;
  border: 1px solid #f0f4ff;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.container h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: var(--grayscale-color-900);
}

.container p {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.523077px;
  color: rgba(21, 21, 21, 0.64);
}

.container .header .date {
  display: flex;
  gap: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.523077px;
  color: rgba(21, 21, 21, 0.64);
}

.container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancelContainer h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: var(--danger-color);
}

.cancelContainer p {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.523077px;
  color: var(--grayscale-color-400);
}

.container .header .cancelButton {
  background: transparent;
  box-shadow: none;
  width: 15px;
  height: 15px;
}

.cancelContainer .buttons {
  margin-top: 8px;
  display: flex;
  gap: 8px;
}

/* .cancel {
  border-radius: 48px;
  box-shadow: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.523077px;
  color: white;
  padding: 9px 12px;
  width: min-content;
  white-space: nowrap;
  background: var(--danger-color);
} */

.cancelContainer .notCancel {
  background: #ffffff;
  border-radius: 48px;
  box-shadow: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.523077px;
  color: var(--grayscale-color-800);
  padding: 9px 12px;
  width: min-content;
  white-space: nowrap;
}

.consultationLink {
  margin-top: 8px;
  background: #ffffff;
  border-radius: 48px;
  box-shadow: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.523077px;
  color: var(--grayscale-color-900);
  border: 1px solid #f0f4ff;
  padding: 9px 12px;
  width: min-content;
  white-space: nowrap;
}

.consultationLink:disabled {
  cursor: inherit;
  opacity: 0.3;
}

.h1 {
  color: var(--grayscale-color-900);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.785px;
  margin: 32px 0 39px 0;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
